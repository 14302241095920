<template>
  <!-- 用户下拉 -->
  <a-dropdown placement="bottomLeft">
    <span class="flex">
      <img class="user-avatar" :src="getImage" />
      <span>{{ userName ? userName : '用户名' }}</span>
    </span>
    <!-- <template #overlay>
      <Menu>
        <MenuItem>
          <container-outlined :style="{ fontSize: '14px' }" />
          <span>文档</span>
        </MenuItem>
        <MenuItem>
          <lock-outlined :style="{ fontSize: '14px' }" />
          <span>锁定屏幕</span>
        </MenuItem>
        <MenuItem>
          <poweroff-outlined :style="{ fontSize: '14px' }" />
          <span>退出系统</span>
        </MenuItem>
      </Menu>
    </template> -->
  </a-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ContainerOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons-vue'
import { storeToRefs } from 'pinia'
import { getImage } from '../../hooks/components/useGetImage'
import { userStore } from '@/store/modules/user'

export default defineComponent({
  components: {
    ContainerOutlined,
    LockOutlined,
    PoweroffOutlined
  },
  setup() {
    const store = userStore()
    const prefixCls = 'brcm-layout-header'

    return {
      prefixCls,
      ...storeToRefs(store),
      getImage: getImage('user.png')
    }
  }
})
</script>

<style scoped lang="less">
.user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}
.flex {
  display: flex !important;
  height: @header-height;
  padding: 0 8px;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: @header-light-bg-hover-color;
  }
}
</style>
