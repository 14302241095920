<template>
  <div>
    <a-layout-header style="background: #fff" :class="`${'brcm'}-layout-header`">
      <!-- 收缩侧边栏按钮 -->
      <menu-unfold-outlined v-if="collapsed" class="trigger" @click="clickCollapsed" />
      <menu-fold-outlined v-else class="trigger" @click="clickCollapsed" />
      <!-- 面包屑 -->
      <Breadcrumb />
      <!-- 顶部右侧用户信息 -->
      <div :class="`${prefixCls}-action`">
        <!-- 用户下拉 -->
        <UserAction />
        <!-- 全屏 -->
        <FullScreen :class="`${prefixCls}-action__item`" />
        <!-- 设置 -->
        <!-- <div ="`${prefixCls}-action__item`">
          <setting-outlined />
        </div> -->
      </div>
      <!-- 退出操作 -->
      <div :class="`${prefixCls}-action__item`">
        <a-tooltip title="退出" placement="bottom" :mouse-enter-delay="0.5">
          <span @click="clickLogoutCloesHandler">退出</span>
        </a-tooltip>
      </div>
    </a-layout-header>
    <!-- tabs -->
    <GlobaleTabs />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MenuUnfoldOutlined, MenuFoldOutlined, SettingOutlined } from '@ant-design/icons-vue'
import { storeToRefs } from 'pinia'
import UserAction from '../components/userAction.vue'
import FullScreen from '../components/fullScreen.vue'
import Breadcrumb from '../components/breadcrumb.vue'
import { menuStore } from '@/store/modules/menu'
import { userStore } from '@/store/modules/user'
import GlobaleTabs from '../GlobaleTabs/index.vue'

export default defineComponent({
  name: 'GlobaleHeader',
  components: {
    UserAction,
    FullScreen,
    Breadcrumb,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingOutlined,
    GlobaleTabs
  },
  setup() {
    const store = menuStore()
    const { collapsed } = storeToRefs(store)
    const prefixCls = 'brcm-layout-header'
    const clickCollapsed = () => {
      store.dispatchCollapsed(!collapsed.value)
    }
    const clickLogoutCloesHandler = () => {
      userStore().logout()
    }
    return {
      collapsed,
      prefixCls,
      clickCollapsed,
      clickLogoutCloesHandler
    }
  }
})
</script>

<style lang="less">
@import url('../index.less');
.trigger {
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s;
  margin-right: 10px;
}
</style>
