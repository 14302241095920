import request from '../../utils/axios'
import * as T from './type'
// 上传文件
const postUploadFiLeURL = '/upload/file'
export const postUploadFiLe: T.IApiPromise<T.IUploadParams> = (data: T.IUploadParams) => {
  return request({
    url: postUploadFiLeURL,
    method: 'POST',
    data
  })
}
// 根据id获取客户详情信息
const getlyCustomerIdURL = '/lyCustomer/getById'
export const getlyCustomerId = (data) => {
  return request({
    url: getlyCustomerIdURL,
    params: data
  })
}
// 登录账号权限
const getUserRoleandresourceURL = '/getuser/roleandresource'
export const getUserRoleandresource = (data) => {
  return request({
    url: getUserRoleandresourceURL,
    params: data
  })
}
// 医院=科室-医生层级关系
const getlyHospitalDeptTreelistURL = '/lyHospitalDept/treelist'
export const getlyHospitalDeptTreelist = (data) => {
  return request({
    url: getlyHospitalDeptTreelistURL,
    params: data
  })
}

// 菜单管理-tree
const postLyResourceTreeURL = '/lyResource/tree'
export const postLyResourceTree = (data) => {
  return request({
    url: postLyResourceTreeURL,
    method: 'POST',
    data
  })
}
// 菜单管理-新增
const putLyResourceSaveURL = '/lyResource/save'
export const putLyResourceSave: T.IApiPromise<T.ILyResourceParams> = (
  data: T.ILyResourceParams
) => {
  return request({
    url: putLyResourceSaveURL,
    method: 'PUT',
    data
  })
}
// 菜单管理-修改
const patchLyResourceUpdateByIdURL = '/lyResource/updateById'
export const patchLyResourceUpdateById: T.IApiPromise<T.ILyResourceParams> = (
  data: T.ILyResourceParams
) => {
  return request({
    url: patchLyResourceUpdateByIdURL,
    method: 'PATCH',
    data
  })
}
// 菜单管理-删除
const deleteLyResourceRemoveByIdURL = '/lyResource/removeById'
export const deleteLyResourceRemoveById: T.IApiPromise<T.ILyResourceDeleteParams> = (
  data: T.ILyResourceDeleteParams
) => {
  return request({
    url: deleteLyResourceRemoveByIdURL,
    method: 'DELETE',
    data
  })
}
// 菜单管理-查询
const postLyResourcePageListURL = '/lyResource/pageList'
export const postLyResourcePageList: T.IApiPromise<T.ILyResourcePageParams> = (
  data: T.ILyResourcePageParams
) => {
  return request({
    url: postLyResourcePageListURL,
    method: 'POST',
    data
  })
}
// 数据字典-新增
const putLyDictionaryAddURL = '/lyDictionary/save'
export const putLyDictionaryAdd: T.IApiPromise<T.ILyDictionaryAddParams> = (
  data: T.ILyDictionaryAddParams
) => {
  return request({
    url: putLyDictionaryAddURL,
    method: 'PUT',
    data
  })
}
// 数据字典-修改
const patchLyDictionaryUpdateURL = '/lyDictionary/updateById'
export const patchLyDictionaryUpdate: T.IApiPromise<T.ILyDictionaryAddParams> = (
  data: T.ILyDictionaryAddParams
) => {
  return request({
    url: patchLyDictionaryUpdateURL,
    method: 'PATCH',
    data
  })
}
// 数据字典-删除
const deleteLyDictionaryRemoveURL = '/lyDictionary/removeById'
export const deleteLyDictionaryRemove: T.IApiPromise<T.ILyResourceDeleteParams> = (
  data: T.ILyResourceDeleteParams
) => {
  return request({
    url: deleteLyDictionaryRemoveURL,
    method: 'DELETE',
    data
  })
}
// 数据字典-查询
const postLyDictionaryPageListURL = '/lyDictionary/pageList'
export const postLyDictionaryPageList: T.IApiPromise<T.ILyDictionaryPageParams> = (
  data: T.ILyDictionaryPageParams
) => {
  return request({
    url: postLyDictionaryPageListURL,
    method: 'POST',
    data
  })
}

// 上传图片
const uploadNoticeImgURL = '/epanda-work-service/epwNotice/uploadImage'
export const uploadNoticeImg = (data, progress) => {
  return request({
    url: uploadNoticeImgURL,
    method: 'post',
    data,
    onUploadProgress: progress
  })
}
