import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'
import { logoutApi } from '@/apis/login'
import { removeCookiesForName, removeToken } from '@/utils/auth'
import { getUserRoleandresource } from '@/apis/common'

const { MODE } = import.meta.env
interface IUserState {
  auth: string[] // 权限的code
  operateAuth: string[]
  userName: string
  userInfo: {
    [id: string]: number | string | Object
  }
}
// 提取菜单权限
const convertAuth = (data, result) => {
  data.forEach((dataItem) => {
    const { childResource, resourceCode, resourceType } = dataItem
    if (resourceType === 'T') result.push(resourceCode)
    if (childResource && childResource.length > 0) {
      convertAuth(childResource, result)
    }
  })
  return result
}
// 提取按钮权限
const convertOperateAuth = (data, result) => {
  data.forEach((dataItem) => {
    const { childResource, resourceCode, resourceType } = dataItem
    if (resourceType === 'B') result.push(resourceCode)
    if (childResource && childResource.length > 0) {
      convertOperateAuth(childResource, result)
    }
  })
  return result
}
const getUserInAfterSet = (state, data: any) => {
  const { lyResourceDtos } = data
  // 菜单权限
  const auth =
    lyResourceDtos !== null && lyResourceDtos.length ? convertAuth(lyResourceDtos, []) : []
  // 按钮权限
  const operateAuth =
    lyResourceDtos !== null && lyResourceDtos.length ? convertOperateAuth(lyResourceDtos, []) : []
  const authObj = {
    auth,
    operateAuth
  }
  return authObj
}
export const userStore = defineStore({
  id: 'userStore',
  state: (): IUserState => {
    return {
      auth: [],
      operateAuth: [],
      userName: '',
      userInfo: {}
    }
  },
  getters: {
    getterAuth: (state) => state.auth,
    getterOperateAuth: (state) => state.operateAuth,
    getterUserName: (state) => state.userName,
    getterUserInfo: (state) => state.userInfo
  },
  actions: {
    getUserInfo(obj: Object = {}) {
      let param
      param = {
        ...obj
      }
      return getUserRoleandresource((param = {})).then(
        ({ data }) => {
          const authObj = getUserInAfterSet(this, data)
          console.log(authObj)
          this.auth = authObj.auth
          this.userInfo = data
          this.operateAuth = authObj.operateAuth
          this.userName = data.nickName
          return Promise.resolve(cloneDeep(authObj.auth))
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    logout: () => {
      logoutApi()
        .then(() => {
          // 临时处理本地登录
          if (MODE === 'dev') {
            window.location.href = 'http://localhost:4000/health/#/login'
          } else {
            // 跳转
            const isTestEnv = MODE === 'prod'
            // 单页面修改地址退出逻辑
            removeToken()
            removeCookiesForName('userName')
            const url = isTestEnv
              ? 'https://health.reindjk.com/#/login'
              : 'https://health.reindjk.com/#/login'
            window.location.href = url
          }
        })
        .catch((err) => {
          // 临时处理本地登录
          if (MODE === 'dev') {
            window.location.href = 'http://localhost:4000/health/#/login'
          } else {
            // 跳转
            const isTestEnv = MODE === 'prod'
            // 单页面修改地址退出逻辑
            removeToken()
            removeCookiesForName('userName')
            const url = isTestEnv
              ? 'https://health.reindjk.com/#/login'
              : 'https://health.reindjk.com/#/login'
            window.location.href = url
          }
        })
    }
  }
})
