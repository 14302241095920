<template>
  <a-config-provider :locale="locale">
    <router-view v-if="isRouterAlive"></router-view>
  </a-config-provider>
</template>

<script lang="ts">
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import moment from 'moment' // 配置moment中文环境
import { defineComponent, ref, nextTick, provide, readonly } from 'vue'
// 引入moment
moment.locale('zh-cn')
export default defineComponent({
  name: 'App',
  setup() {
    // isRouterAlive 控制 router-view 达到刷新效果
    const isRouterAlive = ref<boolean>(true)
    const reload = () => {
      isRouterAlive.value = false
      nextTick(() => {
        isRouterAlive.value = true
      })
    }
    provide('reload', readonly(reload))
    return {
      locale: zhCN,
      isRouterAlive,
      reload
    }
  }
})
</script>
