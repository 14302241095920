import { createApp, Directive } from 'vue'
import antd, { message } from 'ant-design-vue'
import './style/index.less'
import moment from 'moment'
import numeral from 'numeral'
import { QuillEditor } from '@vueup/vue-quill'
import App from './App.vue'
import router from './router/index'
import setupRouter from './permission'
import { setupStore } from './store'
import antdComponents from './components/antdComponents'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import * as directives from './directives'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
// import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
dayjs.locale('zh-cn')

console.log(antdComponents)
// import('ant-design-vue/dist/antd.less')
// const importImages = import.meta.globEager('./assets/images/*')
// console.log(importImages)

async function bootstrap() {
  const app = createApp(App)
  // store 仓库
  setupStore(app)
  // routingGuard 路由守卫
  setupRouter(router)
  app.component('QuillEditor', QuillEditor)
  // 全局组件
  Object.keys(antdComponents).forEach((key) => {
    app.component(key, antdComponents[key])
  })
  // 全局注册指令
  Object.keys(directives).forEach((key) => {
    // app.directive(key, directives[key])
    app.directive(key, (directives as { [key: string]: Directive })[key])
  })

  // 全局变量
  app.config.globalProperties.$message = message
  app.config.globalProperties.$moment = moment
  app.config.globalProperties.$numeral = numeral

  app.use(router).use(antd).mount('#app')
}
bootstrap()
