import type { Router, RouteRecordRaw } from 'vue-router'
import { useTitle } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { toRaw } from '@vue/reactivity'
import { userStore } from '@/store/modules/user'
import { menuStore } from '@/store/modules/menu'
import { requestObj } from '@/utils/axios'

import {
  getToken,
  removeToken,
  setToken,
  getCookiesForName,
  setCookiesForName,
  removeCookiesForName
} from '@/utils/auth'
import { getPro } from './utils/version'

const setupRouter = (router: Router) => {
  // 路由跳转后执行
  // router.afterEach(async () => {
  // 如果不想每个路由都检查是否有新版本,只需要在特点的页面才需要检查版本,可以在这里做白名单判断
  // 兼容版本,如果是新版本则进行刷新并缓存
  // getPro()
  // })
  setCookiesForName('url', window.location.href)

  router.beforeEach(async (to) => {
    getPro()
    if (to.meta && to.meta.title) {
      const title = useTitle()
      title.value = to.meta.title as string
    }
    requestObj.pageID = new Date().getTime()
    const hasToken = getToken()
    const userPhone = getCookiesForName('userPhone')
    // 有token
    if (to.path === '/login') {
      return true
    }
    if (!!hasToken && !!userPhone) {
      // 有token
      if (to.path === '/login') {
        return true
      }
      const storeUser = userStore()
      const { getterAuth } = storeToRefs(storeUser)
      const hasAuth = getterAuth.value && getterAuth.value.length > 0
      // 如果有权限
      if (hasAuth) {
        return true
      }
      // 获取用户信息
      try {
        const auth = await storeUser.getUserInfo()
        console.log(auth, 'auth')
        if (auth.length) {
          const storeMenu = menuStore()
          const successfulRoutes: string[] = await storeMenu.getMenuData(auth)
          successfulRoutes.forEach((route) => {
            router.addRoute(route as unknown as RouteRecordRaw)
          })
          console.log(successfulRoutes, 'successfulRoutes')
          return {
            ...to,
            replace: true
          }
        } else {
          return { path: '/login' }
        }
      } catch {
        removeToken()
        removeCookiesForName('userPhone')
        return { path: '/login' }
      }
    } else {
      console.log(404)
      // 跳到登录去
      if (to.path !== '/login') {
        return { path: '/login' }
      }
      return true
    }
  })
}
export default setupRouter
