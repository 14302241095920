<template>
  <div>
    <icon-font style="font-size: 20px" v-if="meta.icon" :type="meta.icon" />
    <span class="title">{{ meta.title }}</span>
  </div>
</template>

<script lang="ts">
import { createFromIconfontCN } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4301083_icznaquqzr.js'
})
export default defineComponent({
  components: {
    IconFont
  },
  props: {
    meta: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {}
})
</script>

<style scoped lang="less"></style>
