<template>
  <a-sub-menu v-if="menuItem.meta && menuItem.meta.title" :key="menuItem.path">
    <template #title>
      <MenuTitle :meta="menuItem.meta" />
    </template>

    <template v-for="item of menuItem.children">
      <SubMenu v-if="item.children" :key="item.path" :menu-item="item" />
      <a-menu-item v-else-if="item.meta && item.meta.title" :key="item.path">
        <MenuTitle :meta="item.meta" />
      </a-menu-item>
    </template>
  </a-sub-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MenuTitle from './menuTitle.vue'
const SubMenu = {
  name: 'SubMenu',
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  template: `
  <a-sub-menu v-if="menuItem.meta && menuItem.meta.title" :key="menuItem.path">
    <template #title>
      <MenuTitle :meta="menuItem.meta" />
    </template>

     <template v-for="item of menuItem.children">
      <SubMenu v-if="item.children" :key="item.path" :menuItem="item" />
      <a-menu-item v-else-if="item.meta && item.meta.title" :key="item.path">
        <MenuTitle :meta="item.meta" />
      </a-menu-item>
    </template>

  </a-sub-menu>
  `,
  components: {
    MenuTitle
  }
}
export default defineComponent({
  name: 'SubMenu',
  components: {
    MenuTitle,
    SubMenu
  },
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
