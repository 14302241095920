<template>
  <a-layout-sider v-model:collapsed="collapsedChildren" :trigger="null" collapsible>
    <div class="logo">
      <img v-if="collapsedChildren" class="active" src="../../assets/logo_icon.png" alt="" />
      <img v-else class="image" src="../../assets/logo.png" alt="" />
    </div>
    <a-menu
      v-model:selectedKeys="activeMenu"
      theme="dark"
      mode="inline"
      :open-keys="openKeys"
      @click="clickMenu"
      @openChange="onOpenChange"
    >
      <template v-for="(item, i) of getterMenuData">
        <!-- 有子级 -->
        <SubMenu v-if="item.children" :key="i" :menu-item="item" />
        <!-- 没有子级 -->
        <a-menu-item v-else-if="item.meta && item.meta.title" :key="item.path">
          <MenuTitle :meta="item.meta" />
        </a-menu-item>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { menuStore } from '@/store/modules/menu'
import MenuTitle from './components/menuTitle.vue'
import SubMenu from './components/subMenu.vue'
import { AppRouteRecordRaw } from '@/router/type'
import { globalStore } from '@/store/modules/global'

export default defineComponent({
  components: {
    MenuTitle,
    SubMenu
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    interface IState {
      openKeys: string[]
      activeMenu: string[]
      collapsedChildren: boolean
    }
    const state: IState = reactive({
      openKeys: [],
      activeMenu: [],
      collapsedChildren: false
    })
    const { getterMenuData } = menuStore()
    const { push, currentRoute } = useRouter()
    const { path } = useRoute()
    const rootSubMenus = computed(() => getterMenuData.filter((v) => v.children).map((v) => v.path))

    const getOpenKey = (rowRouter: { matched: [] }) => {
      const openKey: { path: string } = rowRouter.matched.filter(
        (route: { path: string; redirect: string }) => route.path !== '/' && route.redirect
      )[0]
      if (openKey) {
        state.openKeys = [openKey.path]
      }
    }
    onMounted(() => {
      state.activeMenu = [path]
      globalStore().add(currentRoute.value as AppRouteRecordRaw)
      getOpenKey(currentRoute.value as any)
    })
    // 监听收缩侧边栏状态
    watch(
      () => props.collapsed,
      (newValue: boolean) => {
        newValue || getOpenKey(currentRoute.value as any)
        state.collapsedChildren = newValue
      }
    )
    // 点击菜单时候调用跳转路由
    const clickMenu = ({ item, key, keyPath }) => {
      state.activeMenu = [key]
      push(key)
    }
    // 监听路由变化赋值及激活状态
    watch(currentRoute, (newValue: any) => {
      state.activeMenu = [newValue.path]
      globalStore().add(newValue as AppRouteRecordRaw)
      if (!props.collapsed) {
        getOpenKey(newValue)
      }
    })
    // 展开当前父级元素菜单其他关闭
    const onOpenChange = (openKeys: string[]) => {
      console.log(openKeys)
      const latestOpenKey = openKeys.find((key) => state.openKeys.indexOf(key) === -1)
      if (rootSubMenus.value.indexOf(latestOpenKey!) === -1) {
        state.openKeys = openKeys
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    }
    return {
      getterMenuData,
      ...toRefs(state),
      onOpenChange,
      clickMenu
    }
  }
})
</script>

<style scoped lang="less">
.logo {
  height: 32px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  margin-bottom: 40px;

  .image {
    width: 150px;
  }
  .active {
    width: 40px;
  }
}
</style>
