<template>
  <div :class="`${prefixCls}-multiple-tabs`">
    <a-tabs
      v-model:activeKey="activeKey"
      size="small"
      :animated="false"
      hide-add
      :tab-bar-gutter="3"
      type="editable-card"
      @change="handleChange"
      @edit="onEdit"
    >
      <a-tab-pane
        v-for="route in getterHistoryRouter"
        :key="route.path"
        :closable="!route.meta.hideTab"
      >
        <template #tab>
          <span>{{ route?.meta?.title || '' }}</span>
        </template>
      </a-tab-pane>
      <template #rightExtra>
        <TabRedo :prefix-cls="prefixCls" />
      </template>
    </a-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { globalStore } from '@/store/modules/global'
import TabRedo from './components/TabRedo.vue'

export default defineComponent({
  components: {
    TabRedo
  },
  setup() {
    const prefixCls = 'brcm'
    const store = globalStore()
    const { push, currentRoute } = useRouter()
    const { getterHistoryRouter } = storeToRefs(store)
    const activeKey = ref(currentRoute.value.path)
    watch(currentRoute, (newValue) => {
      activeKey.value = newValue.path
    })

    const onEdit = (targetKey: string) => {
      const activeIndex = getterHistoryRouter.value.findIndex((v) => v.path === activeKey.value)
      const targetKeyIndex = getterHistoryRouter.value.findIndex((v) => v.path === targetKey)
      if (targetKey === getterHistoryRouter.value.slice(-1)[0].path) {
        push(getterHistoryRouter.value.slice(-2)[0].path)
      } else if (activeIndex === targetKeyIndex) {
        push(getterHistoryRouter.value.slice(targetKeyIndex + 1)[0].path)
      }
      store.delete(targetKey)
    }
    const handleChange = (key: string) => {
      push(key)
    }

    return {
      activeKey,
      onEdit,
      getterHistoryRouter,
      prefixCls,
      handleChange
    }
  }
})
</script>

<style lang="less">
@import url('./index.less');
</style>
