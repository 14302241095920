<template>
  <a-layout class="container">
    <GlobaleSider :collapsed="collapsed" />
    <a-layout>
      <div v-if="showTestHJTost" class="watermark">
        <div class="watermark_red">
          <img class="type-icon" :src="getImageTest" />
        </div>
      </div>
      <!-- 头部 -->
      <GlobaleHeader />
      <!-- 内容 -->
      <a-layout-content style="height: 100%">
        <GlobaleContent />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import GlobaleSider from './GlobaleSider/index.vue'
import { getImage } from '../hooks/components/useGetImage'
import GlobaleContent from './GlobaleContent/index.vue'
import GlobaleHeader from './GlobaleHeader/index.vue'
import { menuStore } from '@/store/modules/menu'
export default defineComponent({
  components: {
    GlobaleContent,
    GlobaleSider,
    GlobaleHeader
  },

  setup() {
    const { MODE } = import.meta.env
    const showTestHJTost = ref(false)
    showTestHJTost.value = MODE === 'prod' ? false : true
    const store = menuStore()
    const { getterCollapsed } = storeToRefs(store)
    const prefixCls = 'brcm-layout-header'
    return {
      prefixCls,
      showTestHJTost,
      collapsed: getterCollapsed,
      getImage: getImage('user.png'),
      getImageTest: getImage('test.png')
    }
  }
})
</script>

<style lang="less" scoped></style>
