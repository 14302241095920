import Cookies from 'js-cookie'

const TokenKey = `health-Admin-Token`

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token: string) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getCookiesForName(name: string) {
  return Cookies.get(name)
}
export function setCookiesForName(name: string, val: string) {
  return Cookies.set(name, val)
}
export function removeCookiesForName(name: string) {
  return Cookies.remove(name)
}
