import Axios, { AxiosRequestConfig } from 'axios'
import { notification, message as antdMessage } from 'ant-design-vue'
import download from 'downloadjs_gauch'
import { getToken } from './auth'
import { userStore } from '@/store/modules/user'

console.log(import.meta.env)
const { VITE_APP_WEB_URL, MODE } = import.meta.env
interface codeMessageType {
  [index: number]: string
}
const codeMessage: codeMessageType = {
  400: '参数错误',
  401: '访问由于凭据无效被拒绝',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  500: '服务器发生错误，请检查服务器。'
}
const requestObj: { pageID: string | number | undefined } = { pageID: '' }
const axios = Axios.create({
  baseURL: MODE === 'dev' ? '/api' : (VITE_APP_WEB_URL as string),
  timeout: MODE === 'prod' ? 180000 : 30000 // 测试3分钟，正式50 秒
})
// 过滤对象属性
const filterProps = (obj: any) => {
  const emptyArr = [undefined, '']
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    return Object.keys(obj).reduce((curr: any, v: any) => {
      // 对象值是数组
      if (Object.prototype.toString.call(obj[v]) === '[object Array]') {
        curr[v] = obj[v].map((element: any) => {
          Object.keys(element).forEach((item) => {
            if (emptyArr.indexOf(element[item]) !== -1) {
              element[item] = null
            }
          })
          return element
        })
      } else if (emptyArr.indexOf(obj[v]) !== -1) {
        curr[v] = null
      } else {
        curr[v] = filterProps(obj[v])
      }
      return curr
    }, {})
  }
  return obj
}
// 请求拦截器（发起请求之前的拦截）
axios.interceptors.request.use(
  (config): AxiosRequestConfig<any> => {
    const { data = {}, params = {}, headers } = config
    if (headers) {
      headers.Authorization = `${getToken()}`
      headers.Token = getToken() || ''
      headers['Access-Control-Allow-Origin'] = '*'
    }
    config.pageID = requestObj.pageID as string | undefined
    if (Object.prototype.toString.call(data) === '[object FormData]') {
      return config
    }
    config.data = filterProps(data)
    if (params) config.params = filterProps(params)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器（获取到响应时的拦截）
axios.interceptors.response.use(
  (res) => {
    console.log(res)
    // 下载文件
    if (res && res.config && res.config.responseType === 'blob') {
      const contentDisposition = res.headers['content-disposition']
      const filename = contentDisposition.split('filename=')[1]
      download(res.data, decodeURI(filename))
      return
    }

    // 正常请求
    if (res && res.data) {
      // 请求正常
      const { code, message } = res.data
      if (code === '200') {
        // code正常
        return res.data
      }
      if (code === '401' || code === '402') {
        // token失效或未登录
        if (code === '401') {
          antdMessage.error('登录失效!')
        }
        userStore().logout()
        return { message }
      }
      // 其他异常
      if (requestObj.pageID === res.config.pageID) notification.error({ message })
      return Promise.reject(res)
    }
    // 请求异常
    if (res && res.status === 200 && !!res.data) {
      console.log(res)
      return res.data
    }
    if (requestObj.pageID === res.config.pageID) notification.error({ message: '服务器错误' })
    return Promise.reject(res)
  },
  (error) => {
    console.dir(error)
    // 如果是当前页面发送的请求
    if (requestObj.pageID === error.config.pageID) {
      let message: string
      if (error.message.indexOf('timeout') !== -1) {
        message = '请求超时，请重试'
      } else {
        const { status, data } = error?.response || {}
        if (status === 500 && !data.code) {
          notification.error({ message: codeMessage[status] || '请求错误' })
          return
        }
        const code = data?.code || '400'
        if (code === '401') {
          antdMessage.error('登录失效!')
          userStore().logout()
          return
        }
        // message = codeMessage[code] || '请求错误'
        message = error?.response?.data?.message || '请求错误'
      }
      notification.error({ message: `${message}` })
    }
    return Promise.reject(error)
  }
)
export { requestObj }
export default axios
