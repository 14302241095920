const componentsFiles = import.meta.globEager('./*.vue')
interface ICurrType {
  [key: string]: any
}
const components = Object.keys(componentsFiles).reduce((curr: ICurrType, v: string) => {
  const cur = curr
  const nameArr = v.replace(/^\.\/(.*)\.vue$/, '$1').split('/')
  const name: string | undefined = nameArr.reverse().find((str: string) => {
    return str !== 'index'
  })
  if (name && name.startsWith('_')) {
    return cur
  }
  if (name) {
    cur[name] = componentsFiles[v].default
  }
  return cur
}, {})
export default components
