import Layout from '../layouts/index.vue'
import contentView from '../layouts/GlobaleContent/index.vue'
export const commonRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404.vue')
  }
]

const authRouter = {
  path: '/',
  component: Layout,
  redirect: '/auth',
  children: [
    {
      path: '/customerManagement',
      name: 'customerManagement',
      meta: {
        title: '客户管理',
        icon: 'icon-kehuguanli',
        auth: ['KHGL']
        // hideTab: true
      },
      component: () => import('/src/views/customerManagement/index.vue')
    },
    {
      path: '/personalProfile',
      name: 'personalProfile',
      meta: {
        title: '健康档案',
        icon: 'icon-record',
        auth: ['GRDA']
        // hideTab: true
      },
      component: () => import('/src/views/personalProfile/index.vue')
    },
    // {
    //   path: '/memberManagement',
    //   name: 'memberManagement',
    //   meta: {
    //     title: '会员管理',
    //     icon: 'icon-huiyuan',
    //     auth: ['HYGL']
    //     // hideTab: true
    //   },
    //   component: () =>
    //     import(
    //       //要使用相对地址
    //       /* webpackChunkName: "legislativeReduction" */ '/src/views/memberManagement/index.vue'
    //     )
    // },
    // {
    //   path: '/bookingManagement',
    //   name: 'bookingManagement',
    //   meta: {
    //     title: '预约管理',
    //     icon: 'icon-dingdan',
    //     auth: ['YYGL']
    //     // hideTab: true
    //   },
    //   component: () => import('/src/views/bookingManagement/index.vue')
    // },
    {
      path: '/orderManagement',
      name: 'orderManagement',
      meta: {
        title: '订单管理',
        icon: 'icon-zhuanjiaanmo',
        auth: ['DDGL']
        // hideTab: true
      },
      component: () => import('/src/views/orderManagement/index.vue')
    },
    {
      path: '/medicalExaminationReport',
      name: 'medicalExaminationReport',
      meta: {
        title: '体检报告',
        icon: 'icon-baogao',
        auth: ['TJBG']
        // hideTab: true
      },
      component: () => import('/src/views/medicalExaminationReport/index.vue')
    },
    {
      path: '/feedback',
      name: 'feedback',
      meta: {
        title: '预约管理',
        icon: 'icon-fankui',
        auth: ['YJFK']
        // hideTab: true
      },
      component: () => import('/src/views/feedback/index.vue')
    },
    {
      path: '/corporateMembership',
      name: 'corporateMembership',
      meta: {
        title: '会员卡管理',
        icon: 'icon-huiyuan',
        auth: ['HYKGL']
        // auth: ['YJFK']
        // hideTab: true
      },
      component: () => import('/src/views/corporateMembership/index.vue')
    },
    {
      path: '/systemManagement',
      name: 'systemManagement',
      meta: {
        title: '系统管理',
        icon: 'icon-xitong',
        auth: ['XTGL']
        // hideTab: true
      },
      component: () => import('../layouts/GlobaleContent/index.vue'),
      children: [
        {
          path: '/systemManagement/accountManagement',
          name: 'accountManagement',
          meta: {
            title: '账号管理',
            auth: ['XTGL_ZHGL']
            // hideTab: true
          },
          component: () => import('/src/views/systemManagement/accountManagement/index.vue')
        },
        {
          path: '/systemManagement/roleManagement',
          name: 'roleManagement',
          meta: {
            title: '角色管理',
            auth: ['XTGL_JSGL']
            // hideTab: true
          },
          component: () => import('/src/views/systemManagement/roleManagement/index.vue')
        },
        {
          path: '/systemManagement/menuManagement',
          name: 'menuManagement',
          meta: {
            title: '菜单管理',
            auth: ['XTGL_CDGL']
            // hideTab: true
          },
          component: () => import('/src/views/systemManagement/menuManagement/index.vue')
        },
        {
          path: '/systemManagement/dataDictionary',
          name: 'dataDictionary',
          meta: {
            title: '数据字典',
            auth: ['XTGL_ZDGL']
            // hideTab: true
          },
          component: () => import('/src/views/systemManagement/dataDictionary/index.vue')
        }
      ]
    },
    // 局部刷新时暂位空页面
    {
      path: '/redirect',
      component: () => import('@/layouts/components/redirect.vue')
    },
    {
      path: '/:catchAll(.*)', // 都不匹配时
      redirect: '/404'
    }
  ]
}
export const authRoutes = [authRouter]
