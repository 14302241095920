<template>
  <span :class="`${prefixClass}__extra-redo`" @click="handleRedo">
    <RedoOutlined :spin="loading" />
  </span>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { RedoOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { globalStore } from '@/store/modules/global'
import { debounce } from '@/utils/utils'

export default defineComponent({
  components: {
    RedoOutlined
  },
  props: {
    prefixCls: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const prefixClass = `${props.prefixCls}-multiple-tabs-content`
    const { replace, currentRoute } = useRouter()
    const loading = ref(false)
    const handleRedo = debounce(() => {
      loading.value = true
      const { path } = currentRoute.value
      replace({
        path: '/redirect'
      })
      path !== '/redirect' && globalStore().addReadonlyPath(path)
      setTimeout(() => {
        loading.value = false
      }, 1200)
    }, 500)

    return {
      prefixClass,
      handleRedo,
      loading
    }
  }
})
</script>

<style scoped></style>
