import { defineStore } from 'pinia'

import { AppRouteRecordRaw } from '@/router/type'

interface IGlobalState {
  historyRoute: AppRouteRecordRaw[]
  readonlyPath: string
}
export const globalStore = defineStore({
  id: 'globalStore',
  state: (): IGlobalState => ({
    historyRoute: [],
    readonlyPath: ''
  }),
  getters: {
    getterHistoryRouter: ({ historyRoute }) => historyRoute,
    getterReadonlyPath: ({ readonlyPath }) => readonlyPath
  },
  actions: {
    add(currRoute: AppRouteRecordRaw) {
      if (this.historyRoute.filter((route) => route.path === currRoute.path).length === 0) {
        if (currRoute.path !== '/redirect') {
          this.historyRoute.push(currRoute)
        }
      }
    },
    delete(currRoute: string) {
      this.historyRoute = this.historyRoute.filter((v) => v.path !== currRoute)
    },
    addReadonlyPath(path: string) {
      this.readonlyPath = path
    }
  }
})
