<template>
  <div>
    <a-modal
      ref="modalRef"
      v-bind="convertAttrs"
      v-model:visible="childrenVisibile"
      :wrap-style="{ overflow: 'hidden' }"
    >
      <slot />
      <template #title>
        <div ref="modalTitleRef" style="width: 100%; cursor: move">{{ title }}</div>
      </template>
      <template #modalRender="{ originVNode }">
        <div :style="transformStyle">
          <component :is="originVNode" />
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, CSSProperties, watch, watchEffect } from 'vue'
import { useDraggable } from '@vueuse/core'

export default defineComponent({
  name: 'SModal',
  props: {
    visibile: { type: Boolean },
    title: { type: String, default: '标题' }
  },
  setup(props, { emit, attrs }) {
    const childrenVisibile = computed({
      get: () => {
        return props.visibile
      },
      set: () => {
        emit('update:visibile', false)
      }
    })
    const convertAttrs = computed(() => {
      const parameters = {
        width: '700px',
        bodyStyle: { padding: '20px 50px' },
        maskClosable: false
      }
      return {
        ...parameters,
        ...attrs
      }
    })
    // 拖拽相关
    const modalTitleRef = ref<HTMLElement>(null)

    const { x, y, isDragging } = useDraggable(modalTitleRef)

    const startX = ref<number>(0)
    const startY = ref<number>(0)
    const startedDrag = ref(false)
    const transformX = ref(0)
    const transformY = ref(0)
    const preTransformX = ref(0)
    const preTransformY = ref(0)
    const dragRect = ref({ left: 0, right: 0, top: 0, bottom: 0 })
    watch([x, y], () => {
      if (!startedDrag.value) {
        startX.value = x.value
        startY.value = y.value
        const bodyRect = document.body.getBoundingClientRect()
        const titleRect = modalTitleRef.value.getBoundingClientRect()
        dragRect.value.right = bodyRect.width - titleRect.width
        dragRect.value.bottom = bodyRect.height - titleRect.height
        preTransformX.value = transformX.value
        preTransformY.value = transformY.value
      }
      startedDrag.value = true
    })
    watch(isDragging, () => {
      if (!isDragging) {
        startedDrag.value = false
      }
    })

    watchEffect(() => {
      if (startedDrag.value) {
        transformX.value =
          preTransformX.value +
          Math.min(Math.max(dragRect.value.left, x.value), dragRect.value.right) -
          startX.value
        transformY.value =
          preTransformY.value +
          Math.min(Math.max(dragRect.value.top, y.value), dragRect.value.bottom) -
          startY.value
      }
    })
    const transformStyle = computed<CSSProperties>(() => {
      return {
        transform: `translate(${transformX.value}px, ${transformY.value}px)`
      }
    })
    return {
      modalTitleRef,
      convertAttrs,
      transformStyle,
      childrenVisibile,
      attrs
    }
  }
})
</script>
