<template>
  <a-breadcrumb :routes="routes">
    <template #itemRender="{ route, paths }">
      <span v-if="routes.indexOf(route) === routes.length - 1">
        {{ route?.meta?.title || '' }}
      </span>
      <router-link v-else :to="`${'/' + paths[paths.length - 1]}`">
        {{ route?.meta?.title || '' }}
      </router-link>
    </template>
  </a-breadcrumb>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'BreadCrumb',
  setup() {
    const { currentRoute, getRoutes } = useRouter()
    console.log(currentRoute)
    const allRouter = getRoutes()
    console.log(allRouter)
    const routes = ref(currentRoute.value.matched.filter((route) => route.path !== '/'))
    console.log(routes)
    watch(currentRoute, (newRouter) => {
      console.log(newRouter)
      routes.value = newRouter.matched.filter((route) => route.path !== '/')
    })
    return {
      routes
    }
  }
})
</script>

<style scoped></style>
