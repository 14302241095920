// 防抖函数
export const debounce = (fn: () => void, delay: number) => {
  let timer: number | null = null // 借助闭包
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = window.setTimeout(fn, delay) // 简化写法
  }
}
export const add = (n1, n2) => {
  const multiple = 10 ** 12
  return (n1 * multiple + n2 * multiple) / multiple
}

/**
 * 对字符加星号处理：除前面几位和后面几位外，其他的字符以星号代替
 *
 * @param content
 *            传入的字符串
 * @param frontNum
 *            保留前面字符的位数
 * @param endNum
 *            保留后面字符的位数
 * @return 带星号的字符串
 */
export const replaceString2Star = (content: string, frontNum: number, endNum: number) => {
  if (content && content.length) {
    if (content.length < 5) {
      return new Array(content.length).join('*')
    } else {
      // 前后两个值显示 中间脱敏
      return (
        content.substring(0, frontNum) +
        new Array(content.length - endNum).join('*') +
        content.substring(content.length - endNum)
      )
    }
  } else {
    return ''
  }
}
