import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import * as T from './type'
import { commonRoutes, authRoutes } from './routers'

const routes = commonRoutes

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes as unknown as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})
export const resetRouters = () => {
  router.replace({ path: '/login' })
  window.location.reload()
}

export default router
