<template>
  <RouterView>
    <template #default="{ Component, route }">
      <transition mode="out-in" appear name="fade-slide">
        <keep-alive v-if="route.meta?.keepAlive || false">
          <component :is="Component" />
        </keep-alive>
        <component :is="Component" v-else />
      </transition>
    </template>
  </RouterView>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GlobaleContent',
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
