import request from '../../utils/axios'
import * as T from './type'
// 登录
const loginURL = `/ly/login`
export const login: T.IApiPromise<T.ILoginParams> = (data: T.ILoginParams) => {
  return request({
    url: loginURL,
    method: 'post',
    data
  })
}
// 修改密码
const updataLoginURL = `/lyUser/update/password`
export const updataLogin: T.IApiPromise<T.ILoginParams> = (data: T.ILoginParams) => {
  return request({
    url: updataLoginURL,
    method: 'patch',
    data
  })
}
// 退出登录
const logoutURL = `/ly/logout`
export const logoutApi = (data = {}) => {
  return request({
    url: logoutURL,
    params: data
  })
}
