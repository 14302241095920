<template>
  <a-tooltip title="全屏" placement="bottom" :mouseEnterDelay="0.5">
    <span @click="toggle">
      <fullscreen-outlined v-if="!isFullscreen" />
      <fullscreen-exit-outlined v-else />
    </span>
  </a-tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons-vue'
import { useFullscreen } from '@vueuse/core'

export default defineComponent({
  name: 'FullScreen',
  components: {
    FullscreenOutlined,
    FullscreenExitOutlined
  },
  setup() {
    const prefixCls: string = 'brcm-header-fullscreen'
    const { toggle, isFullscreen } = useFullscreen()
    return {
      prefixCls,
      toggle,
      isFullscreen
    }
  }
})
</script>

<style scoped lang="less"></style>
