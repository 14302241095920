// import store from '../store'
import { Directive } from 'vue'
import { userStore } from '@/store/modules/user'

export const permission: Directive = {
  // mounted是指令的一个生命周期
  mounted(el, binding) {
    // value 获取用户使用自定义指令绑定的内容
    const { value } = binding
    // 获取用户所有的权限按钮
    const store = userStore()
    const authArr = store.operateAuth
    // 判断用户使用自定义指令，是否使用正确了
    if (value) {
      const hasPermission = authArr.some((role: any) => {
        return value.includes(role)
      })
      if (!hasPermission) {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none'
      }
    } else {
      throw new Error('请查看按钮权限')
    }
  }
}
