import { defineStore } from 'pinia'
import type { RouteRecordRaw } from 'vue-router'
import { authRoutes } from '@/router/routers'
import { globalStore } from '@/store/modules/global'
// 判断route是否有权限
const hasAuth = (auth: string[], route: { meta: { auth: string } }) => {
  if (route.meta && route.meta.auth) {
    return auth.some((v) => route.meta.auth.includes(v))
  }
  return true
}
// 根据权限过滤路由
const filterRoutes = (routes: any[], auth: string[]) => {
  const res: string[] = []
  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasAuth(auth, tmp)) {
      if (tmp.children) {
        tmp.children = filterRoutes(tmp.children, auth)
        if (tmp.children.length === 0) {
          return
        }
        tmp.redirect = tmp.children[0].path
      }
      res.push(tmp)
      if (tmp.meta && tmp.meta.title === '客户管理') {
        globalStore().add(tmp)
      }
      // if (tmp.meta) {
      //   globalStore().add(tmp)
      // }
    }
  })
  return res
}
// 生成菜单数据
const genMenuData = (successfulRoutes: any[]) => {
  const rootRoutes = successfulRoutes.find((v) => v.path === '/')
  return rootRoutes ? rootRoutes.children : []
}
interface IState {
  menuData: any[] // 权限菜单
  rules: any[] // 权限菜单
  collapsed: boolean // 控制收缩侧边栏的变量
}
export const menuStore = defineStore({
  id: 'menuStore',
  state: (): IState => {
    return {
      menuData: [], // 菜单数据
      collapsed: false,
      rules: []
    }
  },
  getters: {
    getterMenuData: (state) => state.menuData,
    getterRules: ({ rules }) => rules,
    // getterAuths: ({ auth }) => auth,
    getterCollapsed: ({ collapsed }) => collapsed
  },
  actions: {
    dispatchCollapsed(Collapsed: boolean) {
      this.collapsed = Collapsed
    },
    getMenuData(auth: string[]) {
      const successfulRoutes = filterRoutes(authRoutes, auth)
      const menuData = genMenuData(successfulRoutes)
      this.menuData = menuData
      return Promise.resolve(successfulRoutes)
    }
  }
})
